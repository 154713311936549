module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-TR62VJF28W"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Houblon Bastien Hops","short_name":"Houblon Bastien Hops","start_url":"/","background_color":"#333333","theme_color":"#333333","display":"minimal-ui","icon":"src/images/logo_black.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5fc2ed3c79c37664a3c4e0cc291e5c73"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
