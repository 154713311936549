// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-blog-post-template-tsx": () => import("./../../../src/templates/BlogPost.template.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/Blog.template.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-generic-template-tsx": () => import("./../../../src/templates/Generic.template.tsx" /* webpackChunkName: "component---src-templates-generic-template-tsx" */),
  "component---src-templates-products-template-tsx": () => import("./../../../src/templates/Products.template.tsx" /* webpackChunkName: "component---src-templates-products-template-tsx" */)
}

